
* {
    font-family: 'Roboto Mono', monospace;
}

.color-circles {
    width: 40px;
    height: 40px;
}

.red {
    background-color: red !important;
}
.orange {
    background-color: orange !important;
}
.blue {
    background-color: blue !important;
}
.light-blue {
    background-color: lightblue !important;
}
.light-red {
    background-color: rgb(255, 73, 73) !important;
}
.green {
    background-color: green !important;
}
.yellow {
    background-color: yellow !important;
}
.brown {
    background-color: brown !important;
}
.black {
    background-color: black !important;
}
.beige {
    background-color: beige !important;
}
.white {
    background-color: white !important;
}
.grey {
    background-color: grey !important;
}
.product-page-main-container {
    /* margin-top: 6vh; */
    display: flex;
    width: 100%;
    /* height: 94vh; */
    /* align-items: center; */
    justify-content: center;
}


.product-page-main-container h2{
    font-weight: normal;
    
}
.product-page-main-container h4{
    font-weight: normal;
    margin-bottom: 0 !important;
    
}

.no-border {
    border: none !important;
    background-color: lightblue !important;
}

.product-page-main-container p{
    font-size: large;
    
}

.text-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: start;

    position: sticky;
    top: var(--navbar-height);
    height: 100%;
    padding-left: 1%;
    padding-right: 1%;
    /* justify-content: space-around; */
    /* gap: 5vh; */

}

.image-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    /* border-right: black solid 5px; */
    z-index: 2;
}

.size-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.size-container h3 {
    padding-right: 1vw;
}

.size-buttons {
    width: 100%;
    height: fit-content;
    /* background-color: transparent ; */
    color: #2C2929  !important;
    font-size: large !important;
    font-weight: bold !important;
}

.myButtons {
    background-color: white;
    border: 1px black solid;
}

.color-buttons {
    width: 50px;
    height: 50px;
}

.option-container {
    display: flex;
    gap: 5px;
}

.image-container img {
    height: fit-content;
    width: 100%;
}

.text-container-top {
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 10%;
}

.input-container {
    flex-direction: row;
    gap: 1vw;
}

.text-container-input {
    width: 40px;
    color: black !important;
}

.desc-container {
    margin-top: 10%;
}

.button-container {
    margin-top: 25%;
    flex-direction: column;
    width: 100%;
    gap: 1.5vh;
}
.text-container-wrapper {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    background: white;
    height: 100%;
    border-radius: 2vw;

}

.myButtons:focus {
    border: 2px red solid;
}

.selected {
    border: 2px blue solid;
}

.disabled {
    background-color: orangered !important;
}

.text-container {
    color: #9b64ff;
}

.text-container * {
    color: #9b64ff;
}

@media (max-width: 1024px) {
    .product-page-main-container {
        flex-direction: column !important;
    }

    .image-container {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .text-container {
        width: 100%;
        position: relative !important;
        padding: 12%;
        padding-top: 1%;
        top: 0;
    }

    .input-container {
        padding-bottom: 1%;
    }
    .color-container {
        padding-bottom: 1%;
    }

    .text-container-top {
        margin-bottom: 2.5%;
        margin-top: 1%;
        border-bottom: #ff7c53 1px solid;

    }

    .product-page-images:nth-child(n+2) {
        width: 100px;
    }

    .button-container {
        margin-top: 5%;
        width: 100%;
    }
}
.navbar-header-text {
    font-size: 15rem;
}

.navbar-image {
    background-image: url("../../public/kumi_logo_tran.png") !important;
    background-size: contain;
    height: 14vh !important;
    width: 100%;
}

.navbar-link-container {
    display: flex;
    gap: 1vw;
    padding-left: 1vw;

}

.navbar-link-container h2 {
    font-size: 1rem;
}
.checkout {
    padding-top: var(--navbar-height);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: row-reverse;
}

#payment-form {
    padding: 1vw;
}

label {
    color: white !important;
}

#root {
    color: white !important
}

.checkout-main-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35vw;
    height: fit-content;
    margin: 2%;
    padding: 2%;
    background-color: #a78dd6;
    border-radius: 6%;
}

.checkout-cart  {
    /* width: 100%; */
    height: 85%;
    justify-content: start !important;
    gap: 10px;
}

.checkout-main-container .cart-text-container {
    width: 100%;
}

.form-entry {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkout-form {
    display: flex;
    flex-wrap: wrap;
    height: 95%;
}

.shipping-option-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20vw;
    height: fit-content;
    
}

.payment-session-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20vw;
    height: fit-content;
}

.finalizeCart-container {
    display: flex;
    flex-direction: column;
    min-height: 20vw;
    width: 100%;
}

.card-element-form {
    width: 100%;
    height: 100%;
}

@media (max-width: 1024px) {
    .checkout {
        flex-wrap: wrap-reverse !important;
    }

    .checkout-cart {
        height: fit-content;
    }
}


.footer {
    background-color: #a78dd6e0;
    /* height: var(--footer-height); */
    width: 100%;
    border-top: 3px black solid;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: height 1s ;
    height: 30px;
    z-index: 100000;
}

.footer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}
.footer-left-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.footer-right-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 1vh;
}

.footer:hover {
    height: 100px;
}

body {
    margin-bottom: 15vh;
}

.footer-container a {
    font-size: clamp(11px, 1.5vw, 20px);
}
.login-main-container {
    padding-top: var(--navbar-height);
    position: relative;    
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form-container {
    margin-top: 5vh;
    width: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.login-bottom-button-group {
    margin-top: 5vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.login-bottom-button-group button {
    font-size: small;
    background-color: transparent !important;
    border: 0;
}

.order-container .success {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    height: 100%;
    width: 90%;
    min-height: 50vh;
    background: #a78dd6;
    align-items: center;
    /* padding-bottom: 10vw; */
    padding: 5%;
    margin-top: 4%;

    margin-bottom: 4%;
    border-radius: 25px;
}

.order-text-container {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    display: flex;
    flex-wrap: wrap;
    /* align-self: end; */
    width: 75%;
    border-bottom: orange 1px solid;
    flex-direction: column;
}

.order-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-shipping-container {
    width: 50%;

}

.order-delivery-container {
    width: 50%;
}

.fs-85 {
    font-size: 85% !important;
}
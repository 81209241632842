.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .container__inner {
    overflow: hidden;
    position: relative;
    width: 80vw;
  }
  
  .container__inner::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 56.25%;
  }
  
  .img__container {
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .img__container > img {
    min-width: 100%;
    max-width: 100%;
  }
  
  .ticker {
    position: absolute;
    max-height: 4rem;
    max-width: 4rem;
    width: 3.5vw;
    height: 3.5vw;
    bottom: 2rem;
    left: 2rem;
  }
  
  .ticker > * {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .ticker > div {
    opacity: 0.2;
    border: solid 3px black;
  }
  
  .ticker > svg {
    fill: none;
    stroke: black;
    stroke-width: 3;
    stroke-dasharray: 120;
    stroke-dashoffset: 120;
  }
  
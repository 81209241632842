.product_card-main-container{
    display: flex !important;
    flex-direction: column !important;

    /* width: 300px;
    height: 420px; */

    align-items: center !important;
    justify-content: center !important;

    /* background-color: rgba(255, 255, 255, 0.226); */
    padding: 0 !important;
    /* width: 20%; */
    /* gap: 2vh; */
    flex: 0 1 25%;
    padding: 0;
    height: 100%;
}

.product_card-img {
    width: 95%;
    /* height: 30vh; */
    height: 95%;
    opacity: 100% !important;

}
.product_card-text_container {
    display: flex;
    /* flex-direction: column; */
    width: 16vw;
    justify-content: space-between;
    /* height: 25%;
    width: 95%; */
    /* opacity: 100% !important;
    justify-content: space-between; */
    /* align-items: center; */
}


@media (max-width: 1024px) {
    .product_card-main-container {
        width: 100px;
        height: 200px;
    }   
    /* .product_card-img {
        width: 200px;
        height: 400px;
    }
    .product_card-text_container {
        width: 200px;
    } */
}





body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --background: var(--sky);
    --accent: var(--white);
  
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
  }
  
  * {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  code {
    font-family: input-mono, monospace;
    font-weight: 400;
    font-style: normal;
  }
  
  /* ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: rgba(125, 235, 255, 0.425);
  } */
  
  ::-webkit-scrollbar-thumb {
    background: black;
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: var(--background);
  }
  
  /* html {
    scroll-snap-type: y mandatory;
  } */
  
  
  section {
    height: 100%;
    /* width: 50vw; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* scroll-snap-align: center; */
    perspective: 500px;
    background-color: none;
    margin-bottom: 1vh;

    /* transition: border 2s ease, margin 1s ease; */

  }

  .section-hover:hover {
    background-color: rgba(255, 255, 255, 0.486);
    /* margin: 5vw 5vh; */
    /* padding-bottom: 1vh; */

    border: 1px solid  rgba(0,0,0,0.7);;
    padding: 1vh;
    /* border-width: 0px; */
    transition: background-color 2s ease
  }

  .product_card-text_container h3{
    font-size: 1.5rem !important;
}
  .product_card-text_container h4{
      font-size: 0.8em !important;
      /* background-color: red; */
  }
  .product_card-text_container {
    /* transition: width 1s ease, height 1s ease;
    height: 40%;
    width: 20vw; */
  }

  .product_card-text_container h2 {
    font-size: 1rem;
    /* background-color: red; */
  }

  .section-hover:hover .product_card-text_container
  {
    height: 100%;
  }

   .product-card-desc {
    height: 0%;
    opacity: 0%;
    
    transition: none;
    transition: height 2s ease;
  } 

  .section-hover:hover .product-card-desc
  {
    height: 100%;
    opacity: 100%;
    transition: opacity 1s ease, height 2s ease;



  }
  /* .sectio */
  
  .product-card-img-container {
    /* transition: width 1s ease, height 1s ease; */
    width: 16vw;
    min-width: 300px;
    height: 450px;
    max-height: 450px;
    position: relative;
    /* max-height: 90vh; */
    margin: 20px;
    background: var(--white);
    overflow: hidden;
  }

  .section-hover:hover .product-card-img-container{
    width: 32vw;
    height: 100%;
  }
  
  .product_card-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  
  .progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
  }
  
  .custom-nav-link {
    height: 100%;
    display: flex !important;
    align-items: center;
    /* flex: 1 0 26%; */
  }

  /* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

.product_card-text_container h2,h3,h4 {
  color: black;
}

a {
  color: black !important;
}


/* * {
    color: black !important;
} */

:root {
    --navbar-color: white;
}
.flex {
    display: flex !important;
}

.navbar-custom {
    justify-content: flex-end;
}


.modal-dialog {
    margin-left: 0 !important;
    margin-right: 2vw !important;
    display: flex !important;
    width: 375px !important;
}

#navbar {
    /* gap: 15%; */
    justify-content: space-evenly;
    padding-top: 1vh;
    max-width: none;
}

.navContainer {
    /* justify-content: space-evenly; */
    /* gap: 25vw;

    justify-content: end; */
    justify-content: space-between !important;
    align-items: center !important;
    display: flex;
    flex-direction: column !important;
    /* gap: 2vh; */
}

.nav-top {
    justify-content: space-between;
    align-items: center;
}

.nav-bot {
    justify-content: center;
    align-items: center;
    width: 100%;
}

.width-100 {
    width: 100%;
}

#navbar {
    background: var(--navbar-color) !important;
    box-shadow: none !important;
    padding: 0% !important;
}

.navbar {
    height: var(--navbar-height);
    /* background-color: rgba(0, 0, 0, 0.123) !important; */
    width: 100%;
    position: fixed !important;
    background: var(--navbar-color) !important;
    margin-right: 5px;
    /* background: url("../../public/kara_art_1.jpg"); */
    /* background-blend-mode: multiply; */
/*  */
    /* border-bottom: 2px solid black; */

    /* height: fit-content !important; */
    flex-wrap: wrap !important;
    z-index: 100;
}

.fit-content {
    height: fit-content;
}
.navbar-center-buttons {
    width: 75%;
    justify-content: space-evenly;
}
.navbar-center-buttons #navbar-shop a{
    color: black;
}

.navbar-nav .nav-link {
    color: white;
}

/* Frame 1 */
.navbar-button-container {
    display: flex;
    gap: 1.5vw;
    align-items: center;
    width: 25%;
    flex-wrap: wrap;
    justify-content: center;
}

.cart-main-container {
    display: flex;
    flex-direction: column;
}

.cart-text-container {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    padding: 2.5%;
    background-color: #00000024;
    gap: 10px;
    width: 100%;
}

.cart-image {
    /* width: 20%;
     */
     width: 150px;
     height: 150px;
}

.modal-dialog {
    width: 25%;
}

.modal-body {
    width: 100%;
}

.modal-content {
    background: white !important;
}

.hits {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.search-container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 30px;
    width: 25%;
    align-items: center;
  
}

.search-container:hover .hits{
    display: flex;
    margin-top: 30px;
    width: fit-content;
}

.ais-SearchBox-form {
    display: flex;
}

.invisible {
    display: none !important;
}

#navbar-shop:hover {

}
#navbar-shop:hover .navbar-shop-dropdown{
    display: flex;

}

.navbar-shop-dropdown {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  
}


button.navbar-mobile{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: none !important;
    /* border-bottom: 1px black solid !important; */
    flex-direction: column;
    background: none ;
    /* height: 5%; */
}


.navbar-shop-dropdown-mobile {
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    animation: 0.1s ease-out 0s 1 slideInFromLeft;
}

.navbar-mobile a{
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: space-between;
}

.mobile-nav-link {
    text-align: right;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .side-navbar {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    top: 0;
    width: 40vw;
    /* background-color: rgba(14, 14, 14, 0.959); */
    background-color: #a78dd6e0;
    /* justify-content: space-between; */
    gap: 3%;

    animation: 0.5s ease-out 0s 1 slideInFromLeft;

    padding-left: 1%;
    padding-right: 1%;
}

.side-navbar-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.side-navbar-footer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-left: 1.3rem;
}

.side-navbar-footer li {
    list-style-type: none !important;
}

.side-navbar-footer .navbar-mobile a {
    font-size: 0.725rem;
    font-family: 'Roboto Mono', monospace !important;
    border-bottom: 1px white solid;
}
.mobile-text {
    color: white !important;
    text-decoration: none;
}
.mobile-text p {
    color: white !important;


}
.navbar-mobile a p {
    color: white !important;
    margin-block-start: 0em;
    margin-block-end: 0em;

}

/* .mobile-text p */

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

.width-100 {
    width: 100%;
}

.search-root {
    width: 100%;
}

.search-input {
    width: 100%;
}
.search-reset {
    display: none !important;
}
.dropdown-title {
    color: black;
    font-size: var(--bs-nav-link-font-size);
}

  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    color: white;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100% !important;
    width: 100% !important; */
    height: unset !important;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.main-container-art {
  z-index: 10;
}

.j-center {
  justify-content: center;
}

.a-center {
  align-items: center;
}

.art-container {
  display: flex;
  height: 100%;
}

#welcome-text {
  color: black !important;
}

#huge-container {
  height: 100%;
}

.huge {
  font-size: 33vw;
  color: black !important;
}

.parallax-layer-focus {
  transition: width 1s ease, height 1s ease, margin 1s ease;
}

.parallax-layer-div {
  transition: width 1s ease, height 1s ease, margin 1s ease;
  width: 25% !important;
  background-color: none;

}

.parallax-col-1 {
  justify-content: flex-start;
  margin-left: 25%;
}
 
.parallax-col-2 {
  justify-content: flex-start;
  margin-left: 50%;
}

.parallax-col-3 {
  justify-content: flex-start;
  margin-left: 75%;
}

.parallax-col-0 {
  justify-content: flex-start;
  
}

.parallax-layer-div-art {
  transition: width 1s ease, height 1s ease, margin 1s ease;
  width: 75% !important;
  /* background-color: none; */
}

.parallax-layer-div-art:hover {
  margin-left: 0% !important;
  width: 100% !important;
  z-index: 10 !important;
}

.parallax-layer-button:active {
  background-color: yellow !important;
  height: 100vh !important;
}

/* .parallax-layer-button:focus {
  background-color: yellow !important;
  height: 100vh !important;
} */

.parallax-layer-button {
  background-color: transparent !important;
  height: 100%;
  width: 100%;
}

.wrapper {
  width: 100%;
  height: 100%;
  background: lightblue;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  grid-gap: 25px;
  padding: 25px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
  will-change: width, height;
} */

.item {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  will-change: transform, opacity;
}

.flex.fill {
  height: 100%;
}



.containers1 {
    overflow: hidden;
}

.kumi_logo_img {
    /* position: absolute; */
    /* width: min-content;
    height: 30vh; */
    /* position: fixed; */
    display: flex !important;
    /* height: 6vw; */
    height: 55px !important;
}

#bg {
    /* background: url("../../public/kara_art_1.jpg"); */
    background: var(--bg-color) !important;
    /* background-position: left top, left 5vh;
    background-repeat: repeat-y, no-repeat;
    background-size: cover, 50vw 30vh; */
    position: fixed;
    /* background-blend-mode: multiply; */
    width: 100%;
    height: 100%;
    z-index: -1;
    /* overflow-y: scroll; */
    /* width: 100%; */
    /* height: 100vh; */
    /* height: 645px;
    width: 5760px; The image width times 3 */
  }

#kumi_logo {
    background: url("../../public/kumi_logo.jpg");
    /* Rectangle 13 */

    position: absolute;
    /* width: 831.78px;
    height: 378.92px; */
    left: -91px;
    top: calc(50% - 378.92px/2 - 671.01px);

    /* background: url(kumi_logo.png); */
    background-blend-mode: multiply;
    transform: rotate(-3deg);

    /* Inside auto layout */
    /* flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0; */

}

#bg {
    /* background: url("../../public/kara_art_2.jpg") no-repeat center center fixed;
    background-size: cover; */
    /* position: absolute; */
    width: 100%;
    height: 100%;
}
.home-main-container{
    height: 100%;
    display: flex;
    margin-top: 0;
    align-items: center;
}
.main-container {
    /* margin-top: var(--navbar-height); */

    /* margin-top: calc(2vh + var(--footer-height)); */
    /* margin-bottom: var(--footer-height); */
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - var(--navbar-height) - var(--footer-height)); */
    justify-content: center;
    /* height: 100%; */
}

.home-form {
    display: flex;
    flex-direction: column;
    width: 35%;
    padding: 5%;
    /* padding-right: 5%; */
    background-color: rgba(125, 235, 255, 0.425);
}

.home-product-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16vh;
}



/* .home-product-container:hover section:not(:hover){
    filter: blur(12px);
} */
/* .home-product-container:hover :not(.section-hover:hover){
    filter: blur(12px);
} */
/* .section-hover {
    pointer-events: auto;
} */
/* .home-product-container:hover :not(.section:hover){
    backdrop-filter: blur(13px);
} */

.home-banner {
    height: 40vh;
    width: 100%;
}
/*  */

.home-product-bar {
    width: 100%;
    background: url(../../public/pawel-czerwinski-ruJm3dBXCqw-unsplash.jpg);
}



.ball {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #FF5722;
    animation: bounce 0.5s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    animation-iteration-count: infinite;
}
  
@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 20vh, 0);
    }
}
/* Prefix Support */
  
  
@-webkit-keyframes bounce {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 200px, 0);
        transform: translate3d(0, 200px, 0);
    }
}


.large-button {
    font-size: 5rem !important;
    text-shadow: .2rem .2rem rgb(59, 59, 219);
}

#progress {
    position: fixed;
    top: 18vh;
    left: 3vw;
    transform: rotate(-90deg);
    z-index: 10;
  }

circle {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none;
  }

.bg {
stroke: black;
opacity: 0.3;
}

#progress .indicator {
stroke: red;
}
.start-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5vh;
    margin-bottom: var(--footer-height);
}

.start-button {
    font-family: 'Calligraffitti', cursive;
    font-weight: 700 !important;
    font-size: 3rem !important;
    /* position: absolute; */
    text-shadow: -15px 5px 20px #ced0d3 !important;
    /* top: 50%;
    left: 50%; */
    /* transform: translate(-50%,-50%); */
    letter-spacing: 0.02em;
    text-align: center;
    color: #F9f1cc !important;
    text-shadow: 5px 5px 0px #FFB650, 
      10px 10px 0px #90B1E0 !important;
}

.start-slider-div {
    box-shadow: black 0px 0px 10px 10px;
}
:root {
    --footer-height: 15vh;
    --navbar-height: 135px;
    --bg-color: linear-gradient(130deg, rgba(174,198,207,1) 45%, rgba(248,200,220,1) 60%, rgba(246,206,252,1) 75%);
    --bg-color-reversed: linear-gradient(-55deg, rgba(174,198,207,1) 45%, rgba(248,200,220,1) 60%, rgba(246,206,252,1) 75%);
    --bg-color-0: linear-gradient(0deg, rgba(174,198,207,1) 45%, rgba(248,200,220,1) 60%, rgba(246,206,252,1) 75%);

    --bg-color-blue: rgba(174,198,207,1);
    --bg-color-purple: rgba(246,206,252,1);
}

* {
    /* font-family: 'Roboto Mono', monospace; */
    /* font-family: 'Sniglet', cursive !important; */
    font-family: 'Noto Sans TC', sans-serif;

    /* font-family: 'Ubuntu', sans-serif; */

}

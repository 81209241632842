.product-row-container {
    /* flex: 1 0 21%; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5vw;
}

.section-hover {
    flex: 1 0 21%;
}

section {
    display: flex !important;
    /* flex: 1 0 21%; */
    /* width: 100%; */
    align-items: center;
    flex-direction: column !important;
}

.product-row-text-container {
    display: flex;
    flex-direction: column;
}
.z15{
    z-index: 15;
}

.carousel-inner {
    height: 100%;
}

.carousel-item {
    height: 100%;
}

.collection-start {
    border: 1px #00000033 solid;
    width: 50%;
    height: 50%;
    min-height: 850px;
    background-repeat: no-repeat !important;
    flex: 1 0 49%;
    background-size: 85% 85% !important;
    background-position: center !important;
    color: black;
}

.collection-container {
    width: 100%;
    height: 100%;
    /* flex-direction: column; */
    flex-wrap: wrap;
    /* border: #ff7c53 2px dotted; */
    /* gap: 2%;
    padding: 2%; */
}

.carousel-headers {
    /* background-color: yellow;
     */
     padding: 1.5%;
     color: #9b64ff;
     font-size: 1.1rem;
     border-bottom: #a78dd6 1px solid;
}


.secondary-color {
    color: #9b64ff;
}

.huge-title {
    font-size: 25rem;
}

.hero {
    width: 100%;
    height: 100%;
     /* background-image: ; */
     position: relative;
     /* padding: 0 5%; */
     display: flex;
     align-items: center;
     justify-content: center;
}

.start-paragraph {
    padding: 5%;
    width: 75%;
}

.hero video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transform: rotate(90deg); */
}

.start-logo {
    width: 65vw;
    object-fit: contain;
}

.mid-text {
    font-size: 1.7rem;
}

.mid-text-large {
    font-size: 8vw;
}

.island-container {
    /* display: flex;
    flex-wrap: wrap; */
    /* gap: 2%; */
    padding: 2%;
    height: fit-content;
    align-items: center;
    margin-top: 15%;
    margin-bottom: 15%;
    /* width: 50% !important; */
}

.island {
    display: flex;
    flex-direction: column;
    /* flex: 1 0 48%; */
    border-radius: 5%;
    width: 75%;
    padding: 1%;
    /* height: fit-content; */
    min-height: 30%;
    /* box-shadow: 11px 0px 20px 0px white; */
    /* background: #ceefff; */
    opacity: 0;
    animation: fade-in 1s ease-in forwards;
    
}

.text-island {
    border-top: #a78dd6 1px solid;
    border-bottom: #a78dd6 1px solid;
    border-radius: 0% !important;
    height: fit-content;
}

.island-header {
    font-size: 1.2rem;
}


.flex-1-0-30 {
    flex: 1 0 30%;
}
@keyframes fade-in {
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

#invisible-island-container {
    width: 100%;
    display: flex;
    position: absolute;
    height: 100%;
    align-items: end;
    gap: 5%;
}

#invisible-island-container .island {
    height: 40%;
}

.carousel .product-card-img-container {
    width: 100% !important;
    min-width: none !important;
    height: 100% !important;
}

.arrow-right {
    position: relative;
    top: 0%;
    right: 10%;
    transform: translate(-50%, -50%);
    transform: rotate(270deg);
    cursor: pointer;
}
.arrow-left {
    position: relative;
    bottom: 0%;
    left: 10%;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
    cursor: pointer;
}
.arrow span {
    display: block;
    width: 5vw;
    height: 5vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: arrow_animate 2s infinite;
    border-color: #a78dd6;
}
.arrow.mid-size span{
    width: 3vw;
    height: 3vw;
}

.mid-size {
    top: 1% !important;
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes arrow_animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.btn-primary {
    --bs-btn-bg: orange !important;
    border: none !important;

}

.btn:disabled {
    --bs-btn-bg: #e1a63a !important;
    border: none !important;
}

.text-island.about {
    /* min-height: none !important; */
    margin-top: 12.5%;

}

.collection {
    right: 10% !important;
    position: relative;
    top: 0% !important;
}


.product-row-main-container {
    transition: background-color 1s linear;
    /* background-color: var(--bg-color); */
}

.bgColor-blue {
    background: var(--bg-color-blue);
}

.bgColor-purple {
    background: var(--bg-color-purple);
}

.bgColor-black {
    background-color: black;
}